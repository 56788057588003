<template>
  <div class="details">
    <div class="box-card">
      <div class="detail-title">
        <div class="plateno">
          <div class="icon"><img src="../../../assets/iconcar.png" /></div>
          <div class="num">{{ realParkRecord.plateNo }}</div>
        </div>
      </div>
      <van-divider :hairline="false"></van-divider>
      <div class="details-deep text item">
        <div class="message">
          <div>订单编号</div>
          <div>{{ realParkRecord.orderId }}</div>
        </div>
        <div class="message">
          <div>车场名称</div>
          <div>{{ realParkRecord.roadName }}</div>
        </div>
        <div class="message">
          <div>泊位编号</div>
          <div>{{ realParkRecord.berthNos }}</div>
        </div>
        <div class="message">
          <div>驶入时间</div>
          <div>{{ realParkRecord.parkingTime }}</div>
        </div>
        <div class="message">
          <div>取证时间</div>
          <div>{{ realParkRecord.uploadTime }}</div>
        </div>
        <div class="message">
          <div>订单金额</div>
          <div>￥{{ (realParkRecord.shouldPay * 1) | rounding }}</div>
        </div>
      </div>
      <van-divider :hairline="false"></van-divider>
      <div style="display: flex; justify-content: end; align-items: center; font-size: 13px; color: #a3a3a3">
        实付<span style="font-size: 18px; color: #eb5757">￥{{ (realParkRecord.shouldPay * 1) | rounding }}</span>
      </div>
    </div>
    </el-card>
    <div class="evidence">
      <div class="top-title">车辆进场取证</div>
      <div class="image text item">
        <div class="block" v-for="fit in imgList" :key="fit">
          <el-image style="width: 100px; height: 100px" :src="fit"></el-image>
        </div>
      </div>
    </div>
    <div style="display: flex; justify-content: center; margin-top: 10px">
      <van-button type="info" @click="$router.go(-1)">返 回</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Realparkrecord",
  data() {
    return {
      imgList: [],
      realParkRecord: {},
    };
  },
  created() {
    this.realParkRecord = this.$route.params;
    this.imgList = this.$route.params.imgs?.split(",").slice(0, -1);
  },
  mounted() {
    this.$nextTick(() => {
      this.setColor();
    });
  },
  methods: {
    setColor() {
      let divHeight = this.getPrizeDropHeight();
      let windowHeight = this.getWindowHeight();
      //当页面实际高度小于可视区域高度的时候，将可视区域高度 赋值给 实际高度
      if (divHeight < windowHeight) {
        if (document.getElementsByClassName("details")[0]) {
          document.getElementsByClassName("details")[0].style.height = windowHeight + "px";
        }
      }
    },
    //获取当前页面实际高度
    getPrizeDropHeight() {
      return document.getElementsByClassName("details")[0].offsetHeight;
    },
    //获取可视区域高度
    getWindowHeight() {
      return document.documentElement.clientHeight || document.body.clientHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  overflow: auto;
  background-color: #f8f8f8;

  .box-card {
    box-sizing: border-box;
    padding: 15px;
    margin: 15px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);

    .detail-title {
      display: flex;
      justify-content: space-between;

      .plateno {
        display: flex;
        align-items: center;

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
          text-align: center;
          background-color: #2370ff;
          border-radius: 50%;

          img {
            width: 12px;
            height: 10px;
          }
        }

        .num {
          margin-left: 5px;
          font-size: 15px;
          color: #333333;
        }
      }

      .paystate {
        font-size: 15px;
        color: #eb5757;
      }
    }

    .details-deep {
      .cash {
        width: 50px;

        span {
          font-size: 20px;
        }
      }

      .message {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
        font-size: 13px;

        :nth-child(1) {
          color: #a3a3a3;
        }

        :nth-child(2) {
          color: #333333;
        }
      }
    }
  }
}

.evidence {
  box-sizing: border-box;
  padding: 15px;

  .top-title {
    font-size: 18px;
    color: #333333;
  }

  .image {
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
  }
}
</style>
